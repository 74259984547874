import React from "react";
import Slider from "react-slick";
import avish from "../assets/home/pt/avish.png";
import ie from "../assets/home/pt/i2e.png";
import iih from "../assets/home/pt/iih.png";
import iim from "../assets/home/pt/iim.png";
import IiM from "../assets/home/pt/iim-2.png";
import logoblack from "../assets/home/pt/logo-black.png";
import meit from "../assets/home/pt/meit.png";
import kltif from "../assets/home/pt/kltif.png";
import acic from "../assets/home/pt/acic.png";
import thub from "../assets/home/pt/thub.png";
import Spacing from "../spacing";

const Responsive = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set the autoplay speed in milliseconds (2 seconds in this case)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="container">
        <div className="row">
          {/* <div className="our_partners">
            <h2>Trusted by</h2>
            <Spacing lg="10" md="10" />
            <p className="our_partners-span"></p>
          </div> */}
          <Spacing lg="60" md="10" />
          <div className="col-md-12 col-lg-12 col-xl-12">
            <Slider {...settings}>
           
              <div className="pt-img">
                <img src={ie} alt="" />
               
              </div>
              <div className="pt-img">
             
                <img src={logoblack} alt="" />
              </div>
              <div className="pt-img">
                <img src={iim} alt="" />
              </div>
              <div className="pt-img">
                <img src={IiM} alt="" />
              </div>
              <div className="pt-img">
                <img src={iih} alt="" />
              </div>
                 <div className="pt-img">
                <img src={avish} alt="" />
              </div>
             
              
              {/* <div className="pt-img">
                <img src={logoblack} alt="" />
              </div> */}
              <div className="pt-img">
                <img src={meit} alt="" />
              </div>
              <div className="pt-img">
                <img src={kltif} alt="" />
              </div>

              <div className="pt-img">
                <img src={acic} alt="" />
              </div>
              <div className="pt-img">
                <img src={thub} alt="" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Responsive;
