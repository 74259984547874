import React from 'react'
import './system.css';
import Spacing from '../spacing'
import phone from '../assets/home/phone.png'
import icons from '../assets/home/icons.png'
import desktop from '../assets/home/desktop.png'

function System() {
    return (
        <>
            <div className=''>
                <div className='system_main_bg_position'>
                <Spacing lg="100" md="80" />
                
                    <div className='container-fluid system_bg_main'>
                        <div className='row system_parent_div'>
                            <div className='col-md-6 col-lg-6 col-xl-6 col-12 col-sm-12'>
                            <div className=''>
                            <img src={phone} alt="" />
                        </div>
                            </div>
                            <div className='col-md-6 col-lg-6 col-xl-6 col-12 col-sm-12'>
                                
                                    <h1>The system is <span>Mobile Enabled</span></h1>
                                    <p>Discover how KODE BLUE is the best choice for efficiently managing critical care scenarios.</p>
                               
                                <Spacing lg="40" md="80" />
                            <img src={icons} alt="" />
                            </div>
                        </div>
                        <div className='row system_parent_div_second'>
                     
                            <div className='col-md-12 col-lg-6 col-xl-6 col-12 col-sm-12'>
                            
                          
                            <Spacing lg="250" md="80" />
                            <img src={icons} alt="" />
                       
                            </div>
                            <div className='col-md-12 col-lg-6 col-xl-6 col-12 col-sm-12'>
                                
                                <Spacing lg="10" md="80" />
                            <img src={desktop} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default System
