import React from 'react'
import critical from '../assets/impact/2.png'

function Critical() {
  return (
    <>
       
       <div className='container critical-bg'>
        <div className='row critrical_row'>
            <div className='col-md-12 col-lg-6 col-sm-12  col-12 critical_colum'>
                <div className='critical-text'>
                    <h1>Accessibility to the</h1> 
                    <h1>critical care!</h1>
                    <p>By simplifying hospital selection for critical care, our system reduces the mortality and morbidity rates during emergencies and improves overall healthcare delivery.
</p>
                </div>
            </div>
            <div className='col-md-12 col-lg-6 col-sm-12 col-12 critical_colum'>
                <div className=''>
                    <img src={critical} alt="Critical Care Image" />
                </div>
            </div>
        </div>
    </div>
   
    </>
  )
}

export default Critical
