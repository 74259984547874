import React from 'react';
import videoImg from '../assets/images/video.png';
import Spacing from '../spacing';
import YouTube from "react-youtube";

function Watch() {
  const videoId = "VpMr_o2-38A";
  
  const opts = {
    playerVars: {
      controls: 1,
      modestbranding: 1,
    },
    width: "560px", // Fixed width for desktop
    height: "315",
  };

  return (
    <>
      <Spacing lg="100" md="10" />
      <div className='container'>
        <div className='row watch_our_vedio_here_layout justify-content-center'> {/* Center align the row */}
          <div className='col-md-12 col-lg-6'>
            <div className='watch_our_vedio_leftside_view'>
              <div className='watch_our_vedio_leftside_emptydiv'></div>
              <div className="youtube-wrapper">
                <YouTube videoId={videoId} opts={opts} className="watch_our_vedio_img" />
              </div>
            </div>
          </div>
          <div className='col-md-12 col-lg-6'>
            <div className='watch_our_vedio_rightside_view'>
              <h2>Watch How We're Redefining Possibilities</h2>
              <div className='watch_our_vedio_rightside_view_line'></div>
              <p>KODE BLUE - Smart assistant in emergencies unveiled. The journey through our emergency solution.</p>
              <p className='watch_our-video_small'>Immediate care in your hands.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Watch;
