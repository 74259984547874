import React, { useEffect } from 'react';
import './style.css';
import Spacing from '../spacing'
import Busines from '../impact/busines'
import Footer from '../footer'
import LoginForm from '../impact/loginForm'
import Client from './client'
import System from './system'
import  SystemOne  from './systemOne'
import { HowWork } from './howWork'
import Navbar from './Navbar'
import Banner from './Banner'
import Watch from './Watch';
import OurImpact from '../requestDemo/ourImpact';
import Bestemergencytransitcareassured from './Bestemergencytransitcareassured'
import Responsive from './responsive';
import VisitorTracker from '../VisitorTracker';

function HomePage() {
    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
          const section = document.querySelector(hash);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <>
            <VisitorTracker />
            <div className='main_section_view'>
                <div className='main_section_view_layout'>
                    <Navbar/>
                    <div className='container-fluid'>
            <div className='row'>
                    <Banner/>
                    </div>
            </div>
                </div>
            </div>
            <Spacing lg="100" md="10" />
            
            <div className='watch_our_vedio_here_view  '>
            <div className='container'>
            <div className='row'>
                <Watch/>
                </div>
                </div>
            </div>
            {/* <div className='Beste_mergency_transitcare_assured_view'> */}
                <Bestemergencytransitcareassured/>
            {/* </div> */}
            <Spacing lg="200" md="10" />
            <div id="howDoes">
            <HowWork id="how-it-works-section"/> 
            </div>
            <Spacing lg="200" md="60" />
            
            <br/>
            <br/>
            <Spacing lg="10" md="10" />
            <div id="product">
            <SystemOne/>
            </div>
            <Spacing lg="60" md="80" />
            {/* <System /> */}
            <div id="testimonial">
            <Client />
            </div>
            <Spacing lg="50" md="80" />
            <Busines />
            <Spacing lg="50" md="80" />
            <OurImpact />
            {/* <Spacing lg="150" md="80" />
            <div className='container'>
            <div className='row'>  <Responsive /></div></div> */}
          
            <Spacing lg="150" md="80" />
            <LoginForm />
            <Spacing lg="100" md="80" />
            <Footer /> 

        </>
    )
}

export default HomePage
