import React, { useEffect, useState } from 'react';
import Logo from "../assets/images/logo.svg";
import RightArrow from "../assets/images/arrow-right.png";
import { Link, NavLink } from 'react-router-dom'; 

function Navbar() {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`navbar_view ${isNavbarFixed ? 'fixed-navbar' : ''}`}>
      <nav className={`navbar navbar-expand-lg navbar-light ${isNavbarFixed ? 'fixed' : ''}`}>
        <div className="container">
          <div className='navbar_logo'>
            <div className="navbar-brand">
              <NavLink to="/"><img src={Logo} alt='Logo' style={{marginTop:'-10%', }}/></NavLink>
            </div>
          </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav navbar_items_view me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a href="/" className="nav-link" activeClassName="active"> Home</a> 
                {/* <NavLink className="nav-link" activeClassName="active" exact to="/">Home</NavLink> */}
              </li>
              <li className="nav-item">
                <a href="/impact" className="nav-link" activeClassName="active"> Impact</a> 
                {/* <NavLink className="nav-link" activeClassName="active" to="/impact">Impact</NavLink> */}
              </li>
              <li className="nav-item">
                {/* <NavLink className="nav-link" activeClassName="active" to="/about"> */}
                <a href="/About" className="nav-link" activeClassName="active"> About Us</a> 
                {/* </NavLink> */}
              </li>
            </ul>

            <div className='navbar_item_flex_view'>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item Login_item">
                  <a className="nav-link" href="#">Login</a>
                </li>
              </ul>
              <a href ="/request">
                <button className='request_demo'>Request Demo <img src={RightArrow} alt="Arrow" /></button>
              </a>
             
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
