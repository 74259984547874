import React from 'react';
import one from '../assets/images/01.png';
import two from '../assets/images/02.png';
import three from '../assets/images/03.png';
import four from '../assets/images/04.png';
import imageOne from '../assets/images/img-1.png';
import imageTwo from '../assets/images/img-2.png';
import imageThree from '../assets/images/img-3.png';
import imageFour from '../assets/images/img-4.png';
import curve1 from '../assets/images/curve-1.svg';
import curve2 from '../assets/images/curve-2.svg';
import curve3 from '../assets/images/curve-3.svg';

import imageMTwo from '../assets/images/m-img-2.png';
import imageMThree from '../assets/images/m-img-3.png';
import mcurve1 from '../assets/images/m-curve-1.svg';
import mcurve2 from '../assets/images/m-curve-2.svg';
import mcurve3 from '../assets/images/m-curve-3.svg';
import Spacing from '../spacing';
import VisitorTracker from '../VisitorTracker';



export const HowWork = () => {
    return (
        <>
            <VisitorTracker />
            <div>
                <div className='container-fluid desktop-div'>
                    <div className='row image-con'>
                        <div className='col-md-2 image-one-div'>
                            <div className=''>
                                <img src={imageOne} className='image-one' />
                            </div>
                        </div>
                        <div className='col-md-10'>
                            <div className='how-it-work'>
                                <div className='how-head'>
                                    <h2>How Does It work?</h2>
                                    <h3>Discover how KODE BLUE is the best choice. </h3>
                                    {/* <h3> efficiently  managing critical care scenarios.  </h3> */}
                                    <span className='the_solution_text_span'></span>
                                </div>
                                <Spacing lg="30" md="80" />
                                <div className='row how-div'>
                                    <div className='col-md-4'>
                                        <div className='how-content' >
                                            <img src={one} />
                                            <h3>Create Case</h3>
                                        </div>
                                    </div>
                                    <div className='col-md-8 how-content-p'>
                                        <p>Paramedics can quickly create a case at the emergency site by selecting basic information using our mobile app</p>
                                    </div>
                                </div>
                                <p className='hr-line'></p>
                                <div className='row how-div'>
                                    <div className='col-md-4'>
                                        <div className='how-content' >
                                            <img src={two} />
                                            <h3>Find the Right Hospital</h3>
                                        </div>
                                    </div>
                                    <div className='col-md-8 how-content-p'>
                                        <p>Our AI-powered mobile application will swiftly identify and prioritizes the best nearby hospitals</p>
                                    </div>
                                </div>
                                <p className='hr-line'></p>
                                <div className='row how-div'>
                                    <div className='col-md-4'>
                                        <div className='how-content' >
                                            <img src={three} />
                                            <h3>Live vital Monitoring</h3>
                                        </div>
                                    </div>
                                    <div className='col-md-8 how-content-p'>
                                        <p>Doctors and paramedics can share patient information electronically in real-time while the patient is being transported</p>
                                    </div>
                                </div>
                                <p className='hr-line'></p>
                                <div className='row how-div'>
                                    <div className='col-md-4'>
                                        <div className='how-content' >
                                            <img src={four} />
                                            <h3>Swift Patient Treatment</h3>
                                        </div>
                                    </div>
                                    <div className='col-md-8 how-content-p'>
                                        <p>With access to real-time distance and estimated arrival times, hospitals can prepare themselves for emergency readiness and swift response</p>
                                    </div>
                                </div>
                                <p className='hr-line'></p>


                                <img src={curve1} className='curve-1' />
                                <img src={curve2} className='curve-2' />
                                <img src={curve3} className='curve-3' />
                                <img src={imageTwo} className='image-two' />
                                <img src={imageThree} className='image-three' />
                                <img src={imageFour} className='image-four' />
                            </div>

                        </div>
                    </div>
                </div>

                {/*  mobile version*/}
                <div className='mobile-divv'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='how-work'>
                                    <h4>How It Works?</h4>
                                    <h2>This is How it Works</h2>
                                    <p className='p-div'></p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='container-fluid mobile-div'>
                        <div className='row section-1'>
                            <div className='col-md-5 col-5'>
                                <img src={imageOne} className='image-1' />
                            </div>
                            <div className='col-md-7 col-7' >
                                <div className='section-1-div'>
                                    <img src={one} className='m-image-one' />
                                    <h3>Create Case</h3>
                                    <p>Paramedics can quickly create a case at the emergency site by selecting basic information using our mobile app</p>
                                </div>
                            </div>
                        </div>
                        <img src={mcurve1} className='m-curve-1' />

                        <div className='row'>
                            <div className='col-md-7 col-7'>
                                <div className='section-2-div'>
                                    <img src={two} className='m-image-one' />
                                    <h3>Find the Right Hospital</h3>
                                    <p>Our AI-powered mobile application will swiftly identify and prioritizes the best nearby hospitals</p>
                                </div>

                            </div>
                            <div className='col-md-5 col-5'>
                                <img src={imageMTwo} className='image-2' />
                            </div>
                        </div>

                        <img src={mcurve2} className='m-curve-2' />

                        <div className='row section-1'>
                            <div className='col-md-5 col-5'>
                                <img src={imageMThree} className='image-1' />
                            </div>
                            <div className='col-md-7 col-7' >
                                <div className='section-1-div'>
                                    <img src={three} className='m-image-one' />
                                    <h3>Live vital Monitoring</h3>
                                    <p>Doctors and paramedics can share patient information electronically in real-time while the patient is being transported</p>
                                </div>
                            </div>
                        </div>
                        <img src={mcurve3} className='m-curve-3' />


                        <div className='row'>
                            <div className='col-md-7 col-7'>
                                <div className='section-2-div'>
                                    <img src={four} className='m-image-one' />
                                    <h3>Swift Patient Treatment</h3>
                                    <p>With access to real-time distance and estimated arrival times, hospitals can prepare themselves for emergency readiness and swift response</p>
                                </div>

                            </div>
                            <div className='col-md-5 col-5'>
                                <img src={imageFour} className='image-2' />
                            </div>
                        </div>


                    </div>

                </div>


            </div>

        </>

    )
}
