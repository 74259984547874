import React from 'react'
import './request.css'
import Spacing from '../spacing'
import arrow from '../assets/icons/arrow.png';
import { NavLink } from 'react-router-dom';


function OurImpact() {
    return (
        <>
            <div className='container'>

                <div className='row'>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-12 col-sm-12'>

                        <div className='enhance_div'>
                            <div className='enha_div'>
                                <h1>50%</h1>
                                <p>Enhanced Response time</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-12 col-sm-12'>

                        <div className='death_div'>
                            <div className='enha_div'>
                                <h1>30%</h1>
                                <p>Death rate can </p>
                                <p>be prevented</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-12 col-sm-12'>

                        <div className='inc_div'>
                            <div className='enha_div'>
                                <h1>2X</h1>
                                <p>Inc. Productivity-Staff   </p>
                                <p> working efficiency</p>
                            </div>
                        </div>
                    </div>

                   
                </div>
                <div className='our_imp'>
                <NavLink to="/impact">
                  <button className='our_impact_button'>Our Impact <span><img src={arrow} alt="arrow" /></span></button>
                </NavLink>
                </div>
                

            </div>



        </>

    )
}

export default OurImpact
