import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './login.css';
import { db, sendEmail } from "../../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Responsive from '../home/responsive';
import Spacing from '../spacing';
export default function Form() {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [successMessage, setSuccessMessage] = useState('');
  // const apiUrl = 'https://zxsyuhj7n4.execute-api.ap-south-2.amazonaws.com/prod/form';

  const onSubmit = async (data) => {
    try {
      
      if (!data.username || !data.password || !data.confirmpwd) {
        setSuccessMessage('Please fill in all the fields.');
      } else {
        if (!isValidEmail(data.username)) {
          setSuccessMessage('Invalid email address.');
          return;
        }

        if (!isValidPhone(data.password)) {
          setSuccessMessage('Invalid phone number.');
          return;
        }

        if (!isValidName(data.confirmpwd)) {
          setSuccessMessage('Name should contain only letters and spaces.');
          return;
        }


        // const response = await fetch(apiUrl, {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({
        //     sheetName: 'SignUps',
        //     email: data.username,
        //     phone: data.password,
        //     organisation: data.confirmpwd,
        //   }),
        // });
        console.log(data);
        const signup = {
          email: data.username,
          phone: data.password,
          organisation: data.confirmpwd,
          timestamp: serverTimestamp(),
        };
        const docRef = await addDoc(collection(db, "signups"), signup);
        // await db.collection('signups').add(signup);
        let body = `Sign up form submission has been received with following details:\nEmail: ${data.username}\nPhone: ${data.password}\nOrganisation: ${data.confirmpwd}`;
        sendEmail("varun@kodeblue.in", "Sign Up form submitted", body);// call function to send mail 
        sendEmail(data.username, "Thank you for Reaching out to Kode Blue", "Hi there\n\nWe have received your request and will reach out to you shortly.\n\nKind Regards,\nKode Blue Technologies");
        // Clear the form fields after successful submission
        setSuccessMessage('Form submitted successfully.');
        // if (response.ok) {
        //   setSuccessMessage('Form submitted successfully.');
        //   reset(); // Clear the form data

        //   setTimeout(() => {
        //     setSuccessMessage('');
        //   }, 2000);
        // } else {
        //   // Handle API error here
        //   setSuccessMessage('Error submitting the form. Please try again later.');
        // }
      }
    } catch (error) {
      console.log(error);
      // Handle network error here
      setSuccessMessage('Network error. Please try again later.');
    }

  };

  // Helper function to validate email
  const isValidEmail = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Helper function to validate phone number
  const isValidPhone = (phone) => {
    // Phone number validation regex (for example, 10-digit numbers)
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  // Helper function to validate name (letters and spaces)
  const isValidName = (name) => {
    // Name validation regex (letters and spaces)
    const nameRegex = /^[A-Za-z\s]+$/;
    return nameRegex.test(name);
  };

  return (
    <div className=" ">
      <section className=''>
        <div className="register ">
          <div className=" ">
            <div className=" plus_image_login">
              <div className="container ">
                <div className="row  ">
                  {/* Left Column */}
                  <div className="col-md-12 col-lg-5 col-xl-6 col-12 col-sm-12 ">
                    <div className="container ">
                      <div className="row ">
                        <div className="col-md-2 col-lg-2"></div>
                        <div className="col-md-10 col-lg-10">
                          <div className='login_right_text'>
                            <h1>Together Towards Better</h1><h1>Health</h1>
                            <div className='boreder_down'>
                              <p></p><span>Trusted by</span><p></p>
                            </div>
                          </div>
                          <div className='container'>
                            <div className='row login_images_div'>
                              {/* Include Responsive component here */}
                              <Responsive />
                              <Spacing lg="10" md="40" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Right Column */}
                  <div className="col-md-12 col-lg-6 col-xl-6 col-12 col-sm-12 ">
                    <div className="container ">
                      <div className=''>
                        <div className="row ">
                          <div className="col-md-9 col-lg-11 col-sm-12 m-auto">
                            <div className='form-bg'>
                              <div className='form-white'>
                                <div className='sign-up-text'>Sign Up</div>
                                <form
                                  id='form'
                                  className='flex flex-col'
                                  onSubmit={handleSubmit(onSubmit)}
                                >
                                  <input type="text" {...register("username", { required: true })} placeholder='Your primary email' required />
                                  {errors.username && <p>Username is required</p>}
                                  <input type="text" {...register("password", { required: true })} placeholder='Phone Number' required />
                                  {errors.password && <p>Password is required</p>}
                                  <input type="text" {...register("confirmpwd", { required: true })} placeholder='Organization Name' required />
                                  {errors.confirmpwd && <p>Organization Name is required</p>}
                                  {successMessage && <p>{successMessage}</p>}
                                  <button className='btn'>Get a call back</button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
