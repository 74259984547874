import React, { useState, useEffect } from 'react';
import DM from '../assets/impact/8.png';
import '../impact/hero.css';
import '../about/contact.css';
import Spacing from '../spacing';
import requestbanner from '../assets/images/request.png';
import Navbar from '../home/Navbar';

import { db, sendEmail } from "../../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

function BannerRequest() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    hospitalName: '',
    designation: '',
  });

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    hospitalName: '',
    designation: '',
  });

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const validateForm = () => {
    let isValid = true;
    const newFormErrors = { ...formErrors };

    for (const field in formData) {
      if (!formData[field].trim()) {
        newFormErrors[field] = 'This field is required';
        isValid = false;
      } else {
        newFormErrors[field] = '';
      }
    }

    setFormErrors(newFormErrors);
    return isValid;
  };

  useEffect(() => {
    if (submitSuccess) {
      const timer = setTimeout(() => {
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          hospitalName: '',
          designation: '',
        });
        setSubmitSuccess(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [submitSuccess]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission here (you can replace this with your actual submission logic)
      console.log('Form submitted:', formData);
      const docRef = await addDoc(collection(db, 'demoRequests'), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        hospitalName: formData.hospitalName,
        designation: formData.designation,
        timestamp: serverTimestamp(), // Use serverTimestamp to generate the current date and time
      });
      let body = `Request Demo form submitted with following details\nName: ${formData.firstName} ${formData.lastName}\nEmail: ${formData.email}\nPhone: ${formData.phoneNumber}\nHospital: ${formData.hospitalName}\nDesignation: ${formData.designation}`;
      sendEmail("varun@kodeblue.in", "Request Demo form submitted", body);// call function to send mail 
      sendEmail(formData.email, "Thank you for Reaching out to Kode Blue", "Hi there\n\nWe have received your request and will reach out to you shortly.\n\nKind Regards,\nKode Blue Technologies");
      // Clear the form fields after successful submission
      setSubmitSuccess(true);
    } else {
      console.log('Form has validation errors');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Define regular expressions for validation
    const nameRegex = /^[a-zA-Z]+$/; // Allow only uppercase and lowercase letters
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/; // Basic email format validation
    const phoneRegex = /^\d{10}$/; // Allow only digits

    const newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);

    const newFormErrors = { ...formErrors };

    // Validate based on the field name
    switch (name) {
      case 'firstName':
      case 'lastName':
        newFormErrors[name] = nameRegex.test(value) ? '' : 'Only letters allowed';
        break;
      case 'email':
        newFormErrors[name] = emailRegex.test(value) ? '' : 'Invalid email format';
        break;
      case 'phoneNumber':
        if (!value) {
          newFormErrors[name] = 'Please enter your phone number';
        } else if (!/^[0-9]+$/.test(value) || value.length !== 10) {
          newFormErrors[name] = 'Phone number should contain only numeric characters and be exactly 10 digits';
        } else {
          newFormErrors[name] = '';
        }
        break;
      default:
        newFormErrors[name] = value.trim() === '' ? 'This field is required' : '';
    }

    setFormErrors(newFormErrors);
  };

  return (
    <>
      <div className="">
        <div className="container-fluid hero_bg"> {/* Use className instead of class */}
          <div className="row"> {/* Use className instead of class */}
            <header>
              <Navbar />
            </header>
            <Spacing lg="50" md="80" />
          </div>
          <div className="container"> {/* Use className instead of class */}
            <div className="row  demo_mobile"> {/* Use className instead of class */}
              <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 hero_mobile_sub"> {/* Use className instead of class */}
                <div className="hero-img"> {/* Use className instead of class */}
                  <div className="request_banner_heading_main"> {/* Use className instead of class */}
                    <h1>Take a Product Tour & A Custom Demo</h1>
                    <img src={requestbanner} alt="" />
                  </div>
                </div>
                <Spacing lg="0" md="30" />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7"> {/* Use className instead of class */}
                <div className="form-wrapper request_form_bg"> {/* Use className instead of class */}
                  <form actions="" className="" onSubmit={handleSubmit}>
                    <Spacing lg="50" md="80" />
                    <h1 className="request_heading-form">Request a Demo</h1>
                    {submitSuccess && (
                      <div className="success-message">Form submitted successfully!</div>
                    )}
                    <div className="input-group_div"> {/* Use className instead of class */}
                      <div className="input-group"> {/* Use className instead of class */}
                        <input
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          required
                        />
                        <label>First Name</label>
                        <div className="error-message">{formErrors.firstName}</div>
                      </div>
                      <div className="input-group"> {/* Use className instead of class */}
                        <input
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          required
                        />
                        <label>Last Name</label>
                        <div className="error-message">{formErrors.lastName}</div>
                      </div>
                    </div>
                    <div className="input-group_div"> {/* Use className instead of class */}
                      <div className="input-group"> {/* Use className instead of class */}
                        <input
                          type="text"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                        <label> Business Email</label>
                        <div className="error-message">{formErrors.email}</div>
                      </div>
                      <div className="input-group"> {/* Use className instead of class */}
                        <input
                          type="text"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          required
                        />
                        <label>Phone Number</label>
                        <div className="error-message">{formErrors.phoneNumber}</div>
                      </div>
                    </div>
                    <div className="input-group"> {/* Use className instead of class */}
                      <input
                        type="text"
                        name="hospitalName"
                        value={formData.hospitalName}
                        onChange={handleInputChange}
                        required
                      />
                      <label>Organization Name</label>
                      <div className="error-message">{formErrors.hospitalName}</div>
                    </div>
                    <div className="input-group"> {/* Use className instead of class */}
                      <input
                        type="text"
                        name="designation"
                        value={formData.designation}
                        onChange={handleInputChange}
                        required
                      />
                      <label>Designation</label>
                      <div className="error-message">{formErrors.designation}</div>
                    </div>
                    <Spacing lg="50" md="80" />
                    <div className="request-button-div"> {/* Use className instead of class */}
                      <button className="request-button" type="submit">
                        Request Demo
                      </button>
                    </div>
                    <Spacing lg="50" md="80" />
                  </form>
                  <Spacing lg="150" md="80" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="50" md="80" />
      </div>
    </>
  );
}

export default BannerRequest;
