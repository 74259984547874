import React from 'react'
import outcome from '../assets/impact/5.png'

function Health() {
  return (
    <>
       
       <div className='container outcome-bg'>
        <div className='row hero_mobile'>
           
            <div className='col-md-12 col-lg-6 col-xl-6 col-sm-12 col-12 hero_mobile_sub factor_colum'>
                <img src={outcome} alt="Healthcare Image" />
            </div>
            <div className='col-md-12 col-lg-6 col-xl-6 col-sm-12 col-12 factor_colum '>
                <div className=''>
                    <div className='outcome-text'>
                        <h1>Advancing Patient Care and Outcomes</h1>
                        {/* <h1>and ecosystem health</h1> */}
                        <p>Faster response times and improved triage lead to better patient outcomes and more effective resource allocation</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    </>
  )
}

export default Health;
