// VisitorTracker.js
import {  logEvent } from "firebase/analytics";
import React, { useEffect, useState } from 'react';
import { analytics } from '../firebaseConfig'; // Import Firebase Analytics

function VisitorTracker() {
  const [uniqueVisitorCount, setUniqueVisitorCount] = useState(0);

  useEffect(() => {
    // Log a custom event when this component mounts
    logEvent(analytics, 'page_view', {
      page_name: window.location.pathname,
    });

    // Fetch the unique visitor count from Firebase Analytics
    const fetchUniqueVisitorCount = async () => {
      try {
        const response = await analytics.getAnalyticsCollection();

        // Get the 'user_engagement' event data (contains unique user counts)
        const userEngagementEvent = response.eventDim.filter(
          (event) => event.name === 'user_engagement'
        )[0];

        // Set the unique visitor count from the event data
        setUniqueVisitorCount(userEngagementEvent.userEngagementCount);
      } catch (error) {
        console.error('Error fetching unique visitor count:', error);
      }
    };

    // Fetch the unique visitor count when the component mounts
    fetchUniqueVisitorCount();
  }, []);

  return (
    <></>
  );
}

export default VisitorTracker;
