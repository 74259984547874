import React from 'react'
import './patentCard.css';
import patent from '../assets/impact/6.png'
import Spacing from '../spacing';

function Patent() {
    return (
        <>
        <div className=''>
            <div className='our_impact_main_head'>
                <h2>Our Impact</h2>
            </div>
        </div>
        <Spacing lg="50" md="80" />
          <div class='container'>
        <div class='row row_patient'>
          <div class='col-md-12 col-lg-6 col-sm-12 col-12 col-xl-6 patient_colum'>
            <div class='container'>
              <div class='row'>
                <div class='patient_card'>
                    <div class='col-md-12 col-lg-5 col-sm-12 col-6 col-xl-7 patient_colum'>
                      <div class='patient_card_sub_text patient_card_sub'>
                        <h3>For Patients</h3>
                        <p>We streamline crucial medical information, enabling efficient interventions and providing a smooth continuum of care.</p>
                      </div>
                    </div>
                    <div class='col-md-12 col-lg-5 col-sm-12 col-6 col-xl-7 patient_colum'>
                      <div class='patient_card_sub'>
                        <h2>50%</h2>
                        <span>Enhanced Response</span>
                        <span>time</span>
                      </div>
                    </div>
                </div>
              </div>
            </div>

            <div class='container'>
            <div class='row'>
                <div class='patient_card'>
                    <div class='col-md-12 col-lg-5 col-sm-12 col-xl-7 col-6 patient_colum'>
                      <div class='patient_card_sub'>
                        <h2>80%</h2>
                        <span>Better Compliance</span>
                        <p class='be-prevent-text'>Scores</p>
                      </div>
                    </div>
                    <div class='col-md-12 col-lg-5 col-sm-12 col-xl-6 col-6  patient_colum'>
                      <div class='patient_card_sub'>
                        <h2>40%</h2>
                        <span>Death Rate Can</span>
                        <p class='be-prevent-text'>Be Prevented</p>
                      </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
            <div class='col-md-12 col-lg-6 col-sm-12 col-12 col-xl-5 col-12 '>
                <div class='patient_img'>
                    <img src={patent} alt="Patient Image" />
                </div>
            </div>
          </div>
    </div>
        </>
    )
}

export default Patent
