import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from './components/impact/Hero';
import Layout from './components/pages/layout';
import './components/home/style.css';   
import HomePage from './components/home/homePage';
import About from './components/about/index';
import ReqestDemo from './components/requestDemo/reqestDemo';
import { HowWork } from './components/home/howWork';
import SystemOne from './components/home/systemOne';
import Client from './components/home/client';
import "./firebaseConfig";
import ContactSection from './components/about/contactSection';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/impact" element={<Hero />} />
        <Route path="/about" element={<About/>} />
        <Route path="/contactSection" element={<ContactSection/>} />
        <Route path="/request" element={<ReqestDemo/>} />
        <Route path="/howWork" element={<HowWork/>} />
        <Route path="/SystemOne" element={<SystemOne/>} />
        <Route path="/Client" element={<Client/>} />
      </Routes>
    </Router>
  );
}

export default App;
