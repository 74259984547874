import React, { useEffect } from 'react';
import Spacing from '../spacing';
import OurValues from './ourValues';
import OurStoryReview from './ourStoryReview';
import ContactSection from './contactSection';
import Footer from '../footer'
import AboutBanner from './aboutBanner';
import Navbar from '../home/Navbar';
import VisitorTracker from '../VisitorTracker';

function About() {
  useEffect(() => {
    const { hash } = window.location;
    if (hash === '#contact') {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  return (
    <>
      <VisitorTracker />
      <Navbar />
      <AboutBanner />
      <Spacing lg="150" md="80" />
      <OurValues />
      <Spacing lg="150" md="80" />
      <OurStoryReview />
      <Spacing lg="150" md="80" />
      <div id="contact">
      <ContactSection />
      </div>
      <Spacing lg="150" md="80" />
      <Footer />
    </>
  )
}

export default About;
