 // Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getFirestore } from "firebase/firestore";
 import { getAuth, signInAnonymously } from 'firebase/auth';
 import {getAnalytics} from 'firebase/analytics'
 import axios from 'axios'; // Import Axios for making HTTP requests
 import { collection, addDoc, serverTimestamp } from "firebase/firestore";
 // TODO: Add SDKs for Firebase products that you want to use
 // https://firebase.google.com/docs/web/setup#available-libraries
 // Your web app's Firebase configuration
 const firebaseConfig = {
    apiKey: "AIzaSyAGEPC0BQNieswD2cLALxBATCRs_k2DuuA",
    authDomain: "kode-blue.firebaseapp.com",
    projectId: "kode-blue",
    storageBucket: "kode-blue.appspot.com",
    messagingSenderId: "468099864622",
    appId: "1:468099864622:web:f982f036ba3801fe72e7bf",
    measurementId: "G-LYCR35J4L0"
  };
  
 // Initialize Firebase
 
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const db = getFirestore(app);

signInAnonymously(auth)
  .then(async (userCredential) => {
    const user = userCredential.user;
    const userId = user.uid;

    // Send tracking data to Firestore
    const trackingData = {
      userId: userId,
      page: 'Home', // Replace with the current page or screen name
      timestamp: serverTimestamp(),
    };

    const docRef = await addDoc(collection(db, 'userTracking'), trackingData);
    console.log('Tracking data added to Firestore with ID:', docRef.id);
  })
  .catch((error) => {
    console.error('Error signing in anonymously:', error);
  });
  const sendEmail = async (to , subject,body) => {
    try {
      const emailData = {
        to: to, // Change the recipient email address
        subject: subject,
        body: body,
      };

      const response = await axios.post('https://api.kodeblue.in/actions/sendEmail', emailData, {
        headers: {
          'x-api-key': 'bzjSEkpOKy5lPPOo8zPOr3We59tKQlAGaJYOYkmz', // Change the API key
          'Content-Type': 'application/json',
        },
      });

      console.log(response.data);
      // Handle the response as needed
    } catch (error) {
      console.error(error);
      // Handle errors here
    }
  };


 // Export firestore database
 // It will be imported into your react app whenever it is needed
 export { db, auth, analytics ,sendEmail};