import React from 'react'
import './ourStory.css';
import Spacing from '../spacing';
import varun from '../assets/about/varun.jpg'
import chetan from '../assets/about/chetan.png'
import kranthi from '../assets/about/kranthi.jpg'
import fardeen from '../assets/about/fardeen.png'
import lin from '../assets/icons/lin.png'
import link from '../assets/icons/link.png'

function OurStoryReview() {
    return (

        <>
            <div className='container'>
                <div className='row'>
                    {/* <div className='col-lg-1 col-xl-1'>
                </div> */}
                    <div className='col-md-12 col-lg-6 col-xl-6 col-sm-12 col-12'>
                        <div className='our_story_review_head'>
                            <h1>Meet the Kode Blue Team <br></br> Trailblazers in Emergency Tech </h1>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-6 col-xl-6 col-sm-12 col-12'>
                        <div className='our_story_review_para'>
                            <p>In the wake of the pandemic, a new perspective dawned upon us, exposing the gaps in Emergency Medical Services.
                                We realized that our focus needed a shift, and thus, Kode Blue Technologies was born</p>
                            {/* <p>Fuelled by an unwavering passion for innovation, we're rewriting the emergency care norms. Patient-Centric is our mantra where every line of code is dedicated to their well-being. Our foundation is our Integrity, our story is one of Excellence,
                             from idea to service, delivering exceptional value. Together, we're redefining emergency care's future</p> */}
                        </div>
                    </div>
                    {/* <div className='col-lg-1 col-xl-1'>
                </div> */}
                </div>
                <Spacing lg="100" md="80" />
                <div className='row'>

                    <div className='col-md-6 col-lg-3 col-xl-3 col-sm-12 col-12'>
                        <div className='our_story_card'>
                            <div className='man_img_div'>
                                <img src={varun} alt="" />
                            </div>
                            <h2>Varun Chintha</h2>
                            <h4>Founder, CEO</h4>
                            <p>Product Head</p>
                            <div className='icons_images'>
                                <a href="https://www.linkedin.com/in/varun-chintha-b46674103/" target='_blank'><img src={lin} alt="social media insta" /></a>
                                {/* <img src={insta} alt="social media insta" />
                        <img src={twitter} alt="social media insta" /> */}
                            </div>

                        </div>
                    </div>

                    <div className='col-md-6 col-lg-3 col-xl-3 col-sm-12 col-12'>
                        <div className='our_story_card '>
                            <div className='man_img_div'>
                                <img src={kranthi} alt="" />
                            </div>
                            <h2>Kranthi Juluri</h2>
                            <h4>Co-Founder, CMO</h4>
                            <p>Sales & Marketing Head</p>
                            <div className='icons_images '>
                                <a href="https://www.linkedin.com/in/kranthi-kumar-goud-juluri-4b2151b5/" target='_blank'><img src={lin} alt="social media insta" /></a>
                                {/* <img src={insta} alt="social media insta" />
                        <img src={twitter} alt="social media insta" /> */}
                            </div>

                        </div>
                    </div>
                    <div className='col-md-6 col-lg-3 col-xl-3 col-sm-12 col-12'>
                        <div className='our_story_card'>
                            <div className='man_img_div'>
                                <img src={fardeen} alt="" />
                            </div>
                            <h2>Fardeen Khan</h2>
                            <h4>CTO</h4>
                            <p>Technology Head</p>
                            <div className='icons_images '>
                                <a href="https://www.linkedin.com/in/fardeenkhan9983/" target='_blank'><img src={lin} alt="social media insta" /></a>

                                {/* <img src={insta} alt="social media insta" />
                        <img src={twitter} alt="social media insta" /> */}
                            </div>

                        </div>
                    </div>
                    <div className='col-md-6 col-lg-3 col-xl-3 col-sm-12 col-12'>
                        <div className='our_story_card'>
                            <div className='man_img_div'>
                                <img src={chetan} alt="" />
                            </div>
                            <h2>Dr. Chetan Chintha</h2>
                            <h4>Advisor</h4>
                            <p>Digital Life Sciences</p>
                            <div className='icons_images '>
                                <a href="https://www.linkedin.com/in/chetan-chintha/" target='_blank'><img src={lin} alt="social media insta" /></a>

                                {/* <img src={insta} alt="social media insta" />
                        <img src={twitter} alt="social media insta" /> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>



        </>

    )
}

export default OurStoryReview
