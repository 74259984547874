import React, { useState } from 'react'; // Import necessary modules
import './contact.css'; // Import the CSS file for styling
import Spacing from '../spacing'; // Import the Spacing component

import phone from '../assets/icons/phone.png';
import mail from '../assets/icons/mail.png';
import loc from '../assets/icons/location.png';
import linkedin from '../assets/about/linkedin_black.svg';
import youtube from '../assets/about/youtube_black.svg';


import { db ,sendEmail} from "../../firebaseConfig"; // Import Firebase Firestore configurations
import { collection, addDoc, serverTimestamp } from "firebase/firestore"; // Import Firestore functions

function ContactSection() {
  // Define state variables using the useState hook
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');

  // Define a function to handle changes in form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    // Clear the phone number error when the user enters a valid phone number
    if (name === 'phoneNumber' && /^[0-9]+$/.test(value) && value.length <= 10) {
      setFormErrors({ ...formErrors, phoneNumber: '' });
    }
  };

  // Define a function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      // Form is valid, you can submit it or perform further actions here
      // Display a success message in green

      // Add form data to Firestore database
      const docRef = await addDoc(collection(db, 'contactUs'), {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        phoneNumber: formValues.phoneNumber,
        message: formValues.message,
        timestamp: serverTimestamp(), 
      });

      // Create a function to send an email using Axios
    

      let body = `Contact Form submitted with following details\nName: ${formValues.firstName} ${formValues.lastName}\nEmail: ${formValues.email}\nPhone: ${formValues.phoneNumber}\nMessage: ${formValues.message}`;
      sendEmail("varun@kodeblue.in", "Contact form submitted", body);// call function to send mail 
      sendEmail(formValues.email, "Thank you for Reaching out to Kode Blue", "Hi there\n\nWe have received your request and will reach out to you shortly.\n\nKind Regards,\nKode Blue Technologies");
      setSuccessMessage('Form submitted successfully!');
      // Reset the form
      setFormValues({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
      });

      // Clear the success message after 2 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);
    } else {
      setFormErrors(errors);

      // Clear the error messages after 2 seconds
      setTimeout(() => {
        setFormErrors({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          message: '',
        });
      }, 2000);
    }
  };

  // Define a function to validate the form
  const validateForm = () => {
    const errors = {};

    if (!formValues.firstName) {
      errors.firstName = 'Please enter your first name';
    } else if (!/^[a-zA-Z0-9]+$/.test(formValues.firstName)) {
      errors.firstName = 'Name should contain only letters and numbers';
    }

    if (!formValues.lastName) {
      errors.lastName = 'Please enter your last name';
    } else if (!/^[a-zA-Z0-9]+$/.test(formValues.lastName)) {
      errors.lastName = 'Name should contain only letters and numbers';
    }

    if (!formValues.email) {
      errors.email = 'Please enter your email';
    } else if (!isValidEmail(formValues.email)) {
      errors.email = 'Invalid email format';
    }

    if (!formValues.phoneNumber) {
      errors.phoneNumber = 'Please enter your phone number';
    } else if (!/^[0-9]+$/.test(formValues.phoneNumber) || formValues.phoneNumber.length > 10) {
      errors.phoneNumber = 'Phone number should contain only numeric characters and be up to 10 digits';
    }

    if (!formValues.message) {
      errors.message = 'Please enter your message';
    }

    return errors;
  };

  // Define a function to check the validity of an email address using a regular expression
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  // Render the component with JSX
  return (
    <>
      <div className='container'>
        <div className='contact_us_text_head'>
          <h1>Contact Us</h1>
          <p>Any question or remarks? Just write us a message!</p>
        </div>
        <Spacing lg='50' md='80' />
        <div className='row contact_main_bg'>
          <div className='col-lg-5 col-xl-5 col-md-12 col-12 col-sm-12 contact_left_bg'>
            <div className='contact_information_div'>
              <h1>KODE BLUE TECHNOLOGIES</h1>
              <Spacing lg='80' md='80' />
              <div className='phone_display_div'>
                {/* ... (your existing JSX for contact information) */}
                <img src={phone} alt='' />
                <p>8919214950</p>
              </div>
              <Spacing lg='30' md='80' />
              <div className='phone_display_div'>
                <img src={mail} alt='' />
                <p>info@kodeblue.in</p>
              </div>
              <Spacing lg='30' md='80' />
              <div className='phone_display_div'>
                <img src={loc} alt='' />
                <p>
                  5-11-1365, Hanuman Nagar, Hanuma Konda, Warangal Urban,
                  Telangana, India. 506009
                </p>
              </div>
              <Spacing lg='80' md='80' />
              <div className='phone_display_div'>
                <a href="https://www.linkedin.com/company/kode-blue-technologies-private-limited/" target="_blank" rel="noopener noreferrer">
                  <img src={linkedin} alt='LinkedIn' width={50} height={50} />
                </a>
                <a href="https://www.youtube.com/@KodeBlueTech" target="_blank" rel="noopener noreferrer">
                  <img src={youtube} alt='YouTube' width={50} height={50} />
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-7 col-xl-7 col-md-12 col-12 col-sm-12'>
            <div className='form-wrapper'>
              <form onSubmit={handleSubmit}>
                <div className='input-group_div'>
                  <div className='input-group'>
                    <input
                      type='text'
                      name='firstName'
                      value={formValues.firstName}
                      onChange={handleChange}
                      required
                      autoComplete='off'
                    />
                    <label>First Name</label>
                    <p className='error-message'>{formErrors.firstName}</p>
                  </div>
                  <div className='input-group'>
                    <input
                      type='text'
                      name='lastName'
                      value={formValues.lastName}
                      onChange={handleChange}
                      required
                      autoComplete='off'
                    />
                    <label>Last Name</label>
                    <p className='error-message'>{formErrors.lastName}</p>
                  </div>
                </div>
                <div className='input-group_div'>
                  <div className='input-group'>
                    <input
                      type='text'
                      name='email'
                      value={formValues.email}
                      onChange={handleChange}
                      required
                      autoComplete='off'
                    />
                    <label>Email</label>
                    <p className='error-message'>{formErrors.email}</p>
                  </div>
                  <div className='input-group'>
                    <input
                      type='text'
                      name='phoneNumber'
                      value={formValues.phoneNumber}
                      onChange={handleChange}
                      required
                      autoComplete='off'
                    />
                    <label>Phone Number</label>
                    <p className='error-message'>{formErrors.phoneNumber}</p>
                  </div>
                </div>
                <div className='input-group message_div'>
                  <input
                    type='text'
                    name='message'
                    value={formValues.message}
                    onChange={handleChange}
                    required
                    autoComplete='off'
                  />
                  <label className='message_label'>Message</label>
                  <p className='error-message'>{formErrors.message}</p>
                </div>
                <Spacing lg='50' md='80' />
                <div className='send-button-div'>
                  <button className='send-button' type='submit'>
                    Send Message
                  </button>
                  {successMessage && (
                    <p className='success-message'>{successMessage}</p>
                  )}
                </div>
              </form>
              <Spacing lg='150' md='80' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactSection;
