import React from 'react'; 
import Spacing from '../spacing';
import BestEmergencyimg from "../assets/images/bestemergencyimg.svg";
import PlusImg from "../assets/images/plus-1.svg"; 

function Bestemergencytransitcareassured() {
    return (
        <>

        <div className='container-fluid best_img'>
        <div className='best_img-1'>
          <div className='container'>
            <Spacing lg="80" md="80" />
            <div className='Beste_mergency_transitcare_assured_layout row'>
            <div className='col-md-7 col-sm-6 Beste_mergency_transitcare_assured_leftside'>
                <img src={BestEmergencyimg} alt='BestEmergencyimg' className='best-emergency-img' /> 
            </div>
            <div className='col-md-5 col-sm-6 Beste_mergency_transitcare_assured_rightside'> 
                <h2>Best Emergency Transit Care Assured.</h2>
                <div className='Best_emergency_transit_care_assured_line'></div>
                <p className='Beste_mergency_transitcare_para'>We streamline medical information for efficient interventions and smooth continuity of care.</p>
                <p className='Beste_mergency_transitcare_para-2'>Making critical care better together.</p>
            </div> 
            </div>
            <Spacing lg="60" md="80" />

            </div>
        </div>

        </div>
        
        

        </>
        

        
    )
}

export default Bestemergencytransitcareassured;