import React, { useState, useEffect } from 'react';
import VectorImg from "../assets/images/Vector.png";
import RightSideArrow from "../assets/images/arrow-right.png"; 
import banner from "../assets/home/banner-1.svg"; 
import { NavLink } from 'react-router-dom';
import Spacing from '../spacing';

function Banner() {
  const texts = ["Ambulances", "Hospitals", "Doctors", "Healthcare Professionals" ,  ]; // Add more texts as needed
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
    <div className=''>
      <div className='row'>
      {/* <div className='col-lg-1 col-xl-1 '> </div> */}
        <div className='col-lg-5 col-xl-5 col-md-6 col-sm-12 col-12  banner_leftside_view'>
        <Spacing lg="180" md="80" />
          <div className='VectorImg_view'>
            <img src={VectorImg} alt='VectorImg' />
            <p>AI-POWERED LIFE-SAVING TECHNOLOGY </p>
          </div>
          <h1>Advanced Emergency Management Software for  <span className='main_banner_head'>{texts[currentTextIndex]}</span></h1>
          <p> Revolutionizing Emergency Medical Services</p>
          <NavLink exact to="/request">
            <button className='request_demo'>Request Demo<img src={RightSideArrow} alt="RightSideArrow" /></button>
          </NavLink>
        </div>

        <div className='col-lg-7 col-xl-7 col-md-6 banner_rightside_view'>
          <img src={banner} alt="Vectors1" className='img-vector'/>
        </div>

      </div>
    </div> 
    
    
    </> 
  )
}

export default Banner;
