import React from 'react'
import './client.css';
import manone from '../assets/home/thub.png'
import kltif from '../assets/home/kltif.png'
import IIMV from '../assets/home/IIMV.png'
import IIHMR from '../assets/home/iih.png'
import stars from '../assets/home/stars.png'
import Spacing from '../spacing'

function Client() {
  return (
    <>
      {/* <div className='container'>
    <div className='row'>
      <div className=''></div>
    </div>
   </div> */}
      <div>
        <section>
          <div className="container">
            <div className='cards_text_head'>
              <h1>Here’s what our amazing partners</h1>
              <h1> are saying</h1>
            </div>
            <Spacing lg="40" md="40" />
            <div className="container man_div_bg">
              <div className="row my-2">
                <div className="col-lg-2  mb-3  ">
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12  mb-3 career-component3">
                  <div className='man_div'>
                    <img src={manone} alt="" className='man_div_img' />
                    <div className='stars_div'>
                      <h4>Team T-Hub</h4>
                      <p>T-Hub – Hyderabad</p>
                      {/* <img src={stars} alt="" /> */}
                    </div>
                  </div>
                  <div className='text_stars'>
                    <p>Kode Blue's innovative approach and dedication to improving EMS are commendable,
                      and we look forward to their continued growth and impact in healthcare. </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12  mb-3 career-component3">


                  <div className='man_div'>
                    <img src={kltif} alt="" className='man_div_img' />
                    <div className='stars_div'>
                      <h4>Team KLTIF</h4>
                      <p>KLTIF - Guntur</p>
                      {/* <img src={stars} alt="" /> */}
                    </div>
                  </div>
                  <div className='text_stars'>
                    <p>Kode Blue has got it all. A definitive solution for Hospitals providing a
                      plethora of features bringing effectiveness and efficiency. </p>
                  </div>
                </div>
                <div className="col-lg-2 mb-3">
                </div>
              </div>
              <div className="row my-2">
                <div className="col-lg-2 mb-3 ">
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12 mb-3 career-component3">
                  <div className='man_div'>
                    <img src={IIMV} alt="" className='man_div_img' />
                    <div className='stars_div'>
                      <h4>Team SIP,</h4>
                      <p>IIM FIELD - IIM Visakhapatnam</p>
                      {/* <img src={stars} alt="" /> */}
                    </div>
                  </div>
                  <div className='text_stars'>
                    <p> IIM FIELD highly recommends Kode Blue, a startup that has the potential to make a significant, life-saving impact in healthcare. </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12  mb-3 career-component3">


                  <div className='man_div'>
                    <img src={IIHMR} alt="" className='man_div_img' />
                    <div className='stars_div'>
                      <h4>Team IIHMR Startups</h4>
                      <p>IIHMR - Jaipur, Rajasthan</p>
                      {/* <img src={stars} alt="" /> */}
                    </div>
                  </div>
                  <div className='text_stars'>
                    <p>Kode Blue has demonstrated remarkable potential with its innovative approach. Their dedication and vision are propelling them toward future success. Excited to witness their journey and growth.</p>
                  </div>
                </div>
                <div className="col-lg-2 mb-3">
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  )
}

export default Client
